import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useLocation, useParams } from 'react-router-dom';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailIcon from '@mui/icons-material/Mail';
import { colors, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import { TagEntities, UserRole } from '../../../constants';
import { deleteUser, getUser } from '../../../setup/api';
import { parseObjectKeyToReadableString } from '../../../setup/helpers/parseObjectKeySyntaxToReadableString';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { ConfirmDeletionModal } from '../../../ui/components/Modals/ConfirmDeletionModal';
import { TagDisplay } from '../../../ui/components/TagManager/TagDisplay';
import { TagManager } from '../../../ui/components/TagManager/TagManager';
import { User } from '../../../ui/helpers';

const profileHeadersAdmin: string[] = [
  'events',
  'orders',
  'statistics',
  'settings',
  'integrations',
  'styleguides',
  'child-accounts',
  'permissions',
];
const profileHeadersQA: string[] = ['orders'];

type CreateUserInformationBoxProps = {
  userValue: string;
  Icon: OverridableComponent<
    SvgIconTypeMap<
      {
        //
      },
      'svg'
    >
  > & { muiName: string };
};

const CreateUserInformationBox: FC<CreateUserInformationBoxProps> = ({ userValue, Icon }) => (
  <a className="d-flex align-items-center text-gray-800 text-hover-primary me-5 mb-2">
    <Icon style={{ fill: colors.grey[400] }} />
    {userValue}
  </a>
);

const createNavItems = (navName: string): JSX.Element => {
  const location = useLocation();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    // Call pendo's pageLoad method when the route changes
    window.pendo &&
      window.pendo.pageLoad({
        page: location.pathname,
      });
  }, [location]);

  const link = `/profile/${id}/${navName}`;
  const className = `nav-link text-active-primary me-6 ${location.pathname === link ? 'active' : ''}`;

  return (
    <li className="nav-item">
      <Link className={className} to={link}>
        <FormattedMessage id={`PERMISSIONS.HEADER.${navName.toUpperCase()}`} />
      </Link>
    </li>
  );
};
const ProfileHeader: FC = () => {
  const intl = useIntl();
  const { request } = useFetch();
  const { id } = useParams<TParams>();
  const { userRole } = User();
  const [user, setUser] = useState<IUser | null>(null);

  const fetchUserInformation = async (id: string) => {
    request(getUser, id).then((user: IUser) => {
      setUser(user);
    });
  };
  const handleDelete = (userId: string, hash?: string): Promise<unknown> => request(deleteUser, userId, hash);

  useEffect(() => {
    if (id) {
      fetchUserInformation(id);
    }
  }, []);

  return (
    <div className="card mb-5 mb-xl-10">
      <div className="card-body pt-9 pb-0">
        <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
          <div className="me-7 mb-4">
            <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
              <AccountBoxIcon sx={{ fontSize: '190px' }} />
            </div>
          </div>

          <div className="flex-grow-1">
            <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center mb-2">
                  <a className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                    {user?.displayName || <FormattedMessage id="PERMISSIONS.USER" />}
                  </a>
                </div>

                <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                  {user && user?.userRole && (
                    <CreateUserInformationBox
                      Icon={AccountCircleIcon}
                      userValue={parseObjectKeyToReadableString(user?.userRole)}
                    />
                  )}
                  {user && user?.email && <CreateUserInformationBox Icon={MailIcon} userValue={user?.email} />}
                  {user && user?.address && user.address.city && (
                    <CreateUserInformationBox Icon={LocationOnIcon} userValue={user?.address?.city} />
                  )}
                </div>
              </div>

              <div className="d-flex my-4">
                <TagManager entityId={id} entityType={TagEntities.USER} />
              </div>
            </div>

            <div style={{ width: '20em' }}>
              <TagDisplay entityId={id} entityType="user" />
            </div>
          </div>
        </div>

        <div className="d-flex overflow-auto h-55px">
          <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
            {userRole === UserRole.ADMIN && profileHeadersAdmin.map((header: string) => createNavItems(header))}
            {userRole === UserRole.QA && profileHeadersQA.map((header: string) => createNavItems(header))}
            {userRole === UserRole.ADMIN && (
              <li className="nav-item" style={{ marginTop: '1em', marginBottom: '0.5em' }}>
                <ConfirmDeletionModal
                  handleDelete={handleDelete}
                  itemId={id}
                  entity="User"
                  subTitle={intl.formatMessage({ id: 'PERMISSIONS.SUBTITLE' })}
                />
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export { ProfileHeader };
