import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';

import { RootState } from '../../../../../setup';
import { getAngles } from '../../../../../setup/api/angle';
import { useFetch } from '../../../../../setup/hooks/fetch.hook';
import { Datagrid } from '../../../../../ui/components/Datagrid/Datagrid';
import { FilterComponent } from '../../../../../ui/components/Filters/FilterComponent';
import { CreateAngleModal } from '../../../../components/angle/CreateAngleModal';
import { renderTestsRow } from '../styleGuideV2/helpers';

/**
 * This component load a list with the angles, a modal openable on click
 * and filter queries
 */
export const AngleList = () => {
  const intl = useIntl();
  const { request, isLoading, abortController } = useFetch();
  const [angles, setAngles] = useState([]);
  const [totalAngles, setTotalAngles] = useState(0);
  const queriesFromRouter = useLocation<string>().search;
  const reloadValue = useSelector((state: RootState) => state.reloadTableModal);

  const angleRowHeaders: [string, string][] = [
    ['name', intl.formatMessage({ id: 'ANGLE.NAME' })],
    ['defaultAngleOverlay', intl.formatMessage({ id: 'ANGLE.DEFAULT.OVERLAY' })],
    ['defaultFrameOverlay', intl.formatMessage({ id: 'ANGLE.FRAME.OVERLAY' })],
  ];

  const fetchAngles = (filter: string) => {
    request(getAngles, filter, abortController.signal)
      .then(data => {
        if (data) {
          setAngles(data[0]);
          setTotalAngles(data[1]);
        }
      })
      .catch();
  };

  useEffect(() => {
    // Call pendo's pageLoad method when the route changes
    window.pendo &&
      window.pendo.pageLoad({
        page: location.pathname,
      });
  }, [location]);

  useEffect(() => {
    fetchAngles(queriesFromRouter);
    if (reloadValue?.angle?.reload) {
      fetchAngles(queriesFromRouter);
    }
    return () => {
      abortController.abort();
    };
  }, [queriesFromRouter]);

  return (
    <Grid container padding={4} className="card">
      <Grid xs={10} marginTop={3}>
        <FilterComponent showSearchFilter showPagination entity="angle" count={totalAngles} />
      </Grid>
      <Grid xs={2} marginTop={3} justifyContent="end" alignItems="baseline" display="flex">
        <CreateAngleModal button />
      </Grid>
      <Grid xs={12} marginTop={3}>
        <Datagrid
          loading={isLoading}
          data={angles}
          headings={angleRowHeaders}
          renderRow={renderTestsRow}
          modalURL="admin/angle"
        />
      </Grid>
    </Grid>
  );
};
