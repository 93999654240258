import { FC, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Chip, Grid } from '@mui/material';

import { getTags } from '../../../../../setup/api';
import { useFetch } from '../../../../../setup/hooks/fetch.hook';
import { Datagrid } from '../../../../../ui/components/Datagrid/Datagrid';
import { FilterComponent } from '../../../../../ui/components/Filters/FilterComponent';
import { TFilterProps } from '../../../../../ui/components/Filters/FilterDropDown';
import { CreateTagModal } from '../../../../components/tag/CreateTagModal';

const renderRows = (headings: Array<Array<string>>, item: { [key: string]: string }) =>
  headings.map(([key]) => (
    <td key={key}>
      {key === 'color' ? (
        <div className="symbol symbol-55px me-2">
          <Chip
            label={item[key]}
            style={{
              backgroundColor: item[key],
              border: '1px solid #e0e0e0',
            }}
          />
        </div>
      ) : (
        item[key]
      )}
    </td>
  ));

const categoryOptions = (intl: IntlShape): { value: string; label: string }[] => [
  {
    value: 'QA_EDITOR',
    label: intl.formatMessage({ id: 'TAG.FILTER.QA_EDITOR' }),
  },
  {
    value: 'QA_COORDINATOR',
    label: intl.formatMessage({ id: 'TAG.FILTER.QA_COORDINATOR' }),
  },
  {
    value: 'IMAGE_ERROR',
    label: intl.formatMessage({ id: 'TAG.FILTER.IMAGE_ERROR' }),
  },
  {
    value: 'PRIORITY',
    label: intl.formatMessage({ id: 'TAG.FILTER.PRIORITY' }),
  },
];
const entityOptions: string[] = ['order', 'user', 'images'];

export const TagList: FC = () => {
  const intl = useIntl();
  const { request, isLoading, abortController } = useFetch();
  const [tagsData, setTagsData] = useState([]);
  const [totalTags, setTotalTags] = useState(0);
  const queriesFromRouter = useLocation().search;

  const tagListHeaders: [string, string][] = [
    ['name', intl.formatMessage({ id: 'TAG.NAME' })],
    ['color', intl.formatMessage({ id: 'TAG.COLOR' })],
    ['category', intl.formatMessage({ id: 'TAG.CATEGORY' })],
    ['entity', intl.formatMessage({ id: 'ENTITY' })],
  ];

  const tagFilterOptions: TFilterProps[] = [
    {
      name: intl.formatMessage({ id: 'TAG.CATEGORY' }),
      query: 'category',
      options: categoryOptions(intl),
    },
    {
      name: intl.formatMessage({ id: 'ENTITY' }),
      query: 'entity',
      options: categoryOptions(intl),
    },
  ];

  useEffect(() => {
    // Call pendo's pageLoad method when the route changes
    window.pendo &&
      window.pendo.pageLoad({
        page: location.pathname,
      });
  }, [location]);

  const fetchTags = () => {
    request(getTags, queriesFromRouter, abortController.signal)
      .then(tagsManyAndCount => {
        if (tagsManyAndCount) {
          setTagsData(tagsManyAndCount[0]);
          setTotalTags(tagsManyAndCount[1]);
        }
      })
      .catch();
  };

  useEffect(() => {
    fetchTags();
    return () => {
      abortController.abort();
    };
  }, [queriesFromRouter]);

  return (
    <Grid container padding={4} className="card">
      <Grid xs={10} marginTop={3}>
        <FilterComponent
          showSearchFilter
          customFilterQueries={tagFilterOptions}
          showPagination
          entity="tags"
          count={totalTags}
        />
      </Grid>

      <Grid xs={2} marginTop={3} justifyContent="end" alignItems="baseline" display="flex">
        <CreateTagModal button />
      </Grid>

      <Grid xs={12} marginTop={3}>
        <Datagrid
          loading={isLoading}
          data={tagsData}
          headings={tagListHeaders}
          renderRow={renderRows}
          modalURL="admin/tag"
        />
      </Grid>
    </Grid>
  );
};
