import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ApexCharts, { ApexOptions } from 'apexcharts';

import { trackMessage } from '../../../pendoUtils';
import { getDealershipOrderStatistics } from '../../../setup/api/statistics';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { StatisticsDropdown } from '../../../ui/components/Dropdown/StatisticsDropdown';
import { TimePeriod } from '../../pages/dashboard/DashboardWrapper';

import { monthsArray } from './TotalAmountOfEntities';

interface OrderByTimePeriod {
  [key: string]: string | number;
  count: number;
}

type Props = {
  className: string;
  iconMUI?: JSX.Element;
  userId: string;
};

const UserOrderDashboard: React.FC<Props> = ({ className, iconMUI, userId }) => {
  const intl = useIntl();
  const chartRef = useRef<HTMLDivElement | null>(null);
  const [series, setSeries] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const { request } = useFetch();
  const status = useSelector((state: any): TimePeriod => state.filters.statistics?.qaStatistics?.timePeriod);

  const getChartOptions = (
    labelColor: string,
    baseColor: string,
    lightColor: string,
    labels: string[],
    series: number[],
  ): ApexOptions => {
    const maximumNumber = Math.max(...series);

    return {
      series: [{ name: intl.formatMessage({ id: 'STATS.USER.UPLOADED_CARS' }), data: series }],
      chart: {
        fontFamily: 'inherit',
        type: 'bar',
        height: '55%',
        toolbar: { show: false },
      },
      plotOptions: { bar: { borderRadius: 10, dataLabels: { position: 'top' } } },
      legend: { show: false },
      dataLabels: {
        enabled: true,
        formatter: val => `${val}  `,
        offsetY: -20,
        style: { fontSize: '12px', colors: [baseColor] },
      },
      fill: {
        colors: undefined,
        opacity: 1,
        type: 'solid',
        gradient: {
          type: 'horizontal',
          shadeIntensity: 0,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 100],
          colorStops: [],
        },
        pattern: {
          style: 'verticalLines',
          width: 6,
          height: 6,
          strokeWidth: 2,
        },
      },
      stroke: {
        curve: 'smooth',
        show: true,
        width: 3,
        colors: [baseColor],
      },
      xaxis: {
        min: 1,
        max: labels.length,
        categories: labels,
        axisBorder: { show: false },
        axisTicks: { show: false },
        crosshairs: { fill: { type: 'gradient' } },
        tooltip: { enabled: false },
      },
      yaxis: {
        min: 0,
        max: maximumNumber + maximumNumber * 0.3,
        labels: { show: false, style: { colors: labelColor, fontSize: '12px' } },
      },
      colors: [lightColor],
      markers: {
        colors: [lightColor],
        strokeColors: [baseColor],
        strokeWidth: 3,
      },
    };
  };

  const getOrderStatistics = async (timePeriod: TimePeriod = TimePeriod.MONTH): Promise<void> =>
    request(getDealershipOrderStatistics, userId, timePeriod).then(res => {
      let labels: string[] = [];
      const count: number[] = [];

      if (res) {
        const ordersByTimePeriod = res[`${timePeriod}lyOrders`];

        trackMessage('UPLOADED_ORDER(CAR)_FILTER_BY_DATE_STATISTICS', 'Handle dropdown filter by date');
        ordersByTimePeriod.forEach((item: OrderByTimePeriod) => {
          labels.push(`${timePeriod.toUpperCase()}: ${item[timePeriod]}`);
          count.push(item.count);
        });
        if (timePeriod === 'month') {
          labels = ordersByTimePeriod.map((item: OrderByTimePeriod) => {
            const month = Number(item[timePeriod]);

            return intl.formatMessage({ id: monthsArray[month - 1] }) as unknown as string;
          });
        }
        setLabels(labels);
        setSeries(count);
      }
    });

  useEffect(() => {
    getOrderStatistics(TimePeriod.MONTH);
  }, []);

  useEffect(() => {
    getOrderStatistics(status);
  }, [status, userId]);

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    if (series.length > 0) {
      const chart = new ApexCharts(chartRef.current, getChartOptions('#1976D2', '#1976D2', '#1976D2', labels, series));

      if (chart) {
        chart.render();
      }

      return () => {
        if (chart) {
          chart.destroy();
        }
      };
    }
  }, [series]);

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className="card-body p-0">
        <div className="d-flex flex-stack card-p flex-grow-1">
          <span className="symbol symbol-50px  symbol-light-#1976D2 me-2 d-flex">
            <span className="symbol-label">{iconMUI && iconMUI}</span>
            <div className="me-2 mx-5">
              <a className="text-dark text-hover-primary fw-bolder fs-3">
                <FormattedMessage id="STATS.USER.UPLOADED_CARS" />
              </a>
              <div className="text-muted fs-7 fw-bold">
                <FormattedMessage id="STATS.PER_ENTITY" values={{ entity: status }} />
              </div>
            </div>
          </span>

          <div className="d-flex flex-column text-end">
            <StatisticsDropdown
              title={intl.formatMessage({ id: 'STATS.TIME_FRAME' })}
              options={['month', 'week', 'day']}
              chartName="qaStatistics"
            />
          </div>
        </div>
        <div className="card-p pb-0">
          <div className="d-flex flex-stack flex-wrap" />
        </div>
        <div ref={chartRef} className="statistics-widget-4-chart card-rounded-bottom" style={{ height: '150px' }} />
      </div>
      {/* end::Body */}
    </div>
  );
};

export { UserOrderDashboard };
