import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';

import { Permissions } from '../../../carswip_shared_models/src/web_view_models/permissions.model';
import { LANGUAGES } from '../../../constants';
import { updateUser } from '../../../setup/api';
import { LanguageDropdown } from '../../../ui/components/Dropdown/LanguageDropdown';
import { User } from '../../../ui/helpers';
import { currentLanguageIsTheSame, getLocalStorageLanguage, setLanguage } from '../../i18n/i18nProvider';

export const CreateShortcut: FC = () => {
  const location = useLocation();
  const user = User();
  const [language, setCurrentLanguage] = useState<string>(user?.language || getLocalStorageLanguage() || 'en');

  if (user.language && user.language !== null && !currentLanguageIsTheSame(user.language)) {
    setLanguage(user.language);
  }

  useEffect(() => {
    // Call pendo's pageLoad method when the route changes
    window.pendo &&
      window.pendo.pageLoad({
        page: location.pathname,
      });
  }, [location]);

  const changeLanguage = async ({ target: { value } }: SelectChangeEvent) => {
    try {
      setCurrentLanguage(value);
      await updateUser({ language: value } as any, user.id);
      setLanguage(value);
    } catch (err) {
      throw new Error(`Error updating language in user: ${err}`);
    }
  };

  return (
    <div id="kt_toolbar" style={{ backgroundColor: 'transparent', display: 'flex', padding: '0.5rem' }}>
      {/* begin::Container */}
      <div id="kt_toolbar_container" className="d-flex flex-stack container-fluid">
        {/* begin::Actions */}
        <div className="d-flex align-items-center py-1">
          <div style={{ marginRight: '10px' }}>
            <div>
              <LanguageDropdown onChange={changeLanguage} value={language} options={LANGUAGES} />
            </div>
          </div>
          {/* begin::Button */}
          {location.pathname === '/customers' ? (
            <Link to="/create-account" className="btn btn-sm btn-primary">
              <FormattedMessage id="SHORTCUT.CREATE_ACCOUNT" />
            </Link>
          ) : (
            user.permissions.find(x => x.toLowerCase() === Permissions.CREATE_ORDERS.toLowerCase()) && (
              <Link to="/create-order" className="btn btn-sm btn-primary" style={{ right: '0' }}>
                <FormattedMessage id="SHORTCUT.CREATE_ORDER" />
              </Link>
            )
          )}
          {/* end::Button */}
        </div>
        {/* end::Actions */}
      </div>
      {/* end::Container */}
    </div>
  );
};
