import { Children, FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Chip, Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { parseObjectKeyToReadableString } from '../../../setup/helpers/parseObjectKeySyntaxToReadableString';

import { CountryFilter } from './CountryFilterDropdown';
import { dateFilterTypes, DatePickerFilter } from './DateFilter';
import { FilterDropDown, TFilterProps } from './FilterDropDown';
import { PaginationFilter } from './PaginationFilter';
import { ResetFiltersButton } from './ResetFiltersButton';
import { SearchFilter } from './SearchFilter';

// Item Grid
const Item: FC = ({ children }) => (
  <Grid xs={3} marginBottom={1} marginRight={1}>
    {children}
  </Grid>
);

const renderSearchFilter = () => (
  <Item>
    <SearchFilter />
  </Item>
);
// Maps and render the custom filters
const renderCustomizedFilters = (queries: TFilterProps[]) =>
  queries.map(props => (
    <Item>
      <FilterDropDown {...props} />
    </Item>
  ));
const renderToDateFilter = () => (
  <Item>
    <DatePickerFilter dateFilterType={dateFilterTypes[0]} />
  </Item>
);
const renderFromDateFilter = () => (
  <Item>
    <DatePickerFilter dateFilterType={dateFilterTypes[1]} />
  </Item>
);
const renderCountryFilter = () => (
  <Item>
    <CountryFilter />
  </Item>
);

const DropDownColumn: FC = ({ children }) => {
  const arrayChildren = Children.toArray(children);

  return (
    <Grid container xs={6} style={{ position: 'relative' }}>
      {Children.map(arrayChildren, (child, index) => {
        if (arrayChildren.length === 1) {
          return (
            <>
              {child}
              <ResetFiltersButton />
            </>
          );
        }
        if (index === 2) {
          return (
            <>
              {child}
              <ResetFiltersButton />
            </>
          );
        }
        return <>{child}</>;
      })}
    </Grid>
  );
};
const PaginationColumn: FC = ({ children }) => (
  <Grid xs={6} container direction="row" justifyContent="flex-end" alignItems="flex-start">
    {children}
  </Grid>
);

/**
 * Theme to wrap all dropdown with a fixed value of 100% of the container size
 * for this case on the component {@link Item }
 *
 * This is necessary because MuiTextField & MuiInputBase have two different size percentages by default
 */
const dropdownTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minWidth: '100%',
        },
      },
    },
  },
});

type Props = {
  entity: string;
  customFilterQueries?: TFilterProps[];
  showPagination?: boolean;
  showDateFilter?: boolean;
  showSearchFilter?: boolean;
  showCountryFilter?: boolean;
  count?: number;
};
/**
 * Renders a component with custom filters for the search, which add the queries to the URL
 * which can be called from any other component to send a request to the API by just adding the query string
 * from location.search by using the hook useLocation()
 * @param {string} props.entity name of the entity that will be
 * @param {TFilterProps []} props.customFilterQueries the array should contain all the information needed to load the custom filter dropdowns
 * @param {number} props.totalValues all the values from the response
 * @param {boolean} props.showDateFilter if true, it will render Date Filter from and to
 * @param {boolean} props.showPagination if true, it will render a pagination column
 * @returns {JSX.Element} Customized Grid with all assign filters
 */
export const FilterComponent: FC<Props> = ({
  entity,
  count,
  showDateFilter,
  customFilterQueries,
  showPagination,
  showSearchFilter,
  showCountryFilter,
}) => {
  const intl = useIntl();
  const totalValuesToBeDisplayedOnList = {
    label: intl.formatMessage(
      { id: 'DATA.FILTER.TOTAL.ENTITY' },
      { entity: parseObjectKeyToReadableString(entity), count },
    ),
    style: {
      fontWeight: '500',
      fontSize: '1.075rem',
      marginRight: '.5em',
    },
  };
  const totalAmountOfPages = count ? count / 10 : 0;

  return (
    <ThemeProvider theme={dropdownTheme}>
      <Grid container>
        <DropDownColumn>
          {/* Column to separate the dropdowns from the Reset Button */}
          {showSearchFilter && renderSearchFilter()}
          {showDateFilter && renderToDateFilter()}
          {showDateFilter && renderFromDateFilter()}
          {showCountryFilter && renderCountryFilter()}
          {customFilterQueries && renderCustomizedFilters(customFilterQueries)}
        </DropDownColumn>
        {showPagination && (
          <PaginationColumn>
            <PaginationFilter maxPage={totalAmountOfPages} />
            <Chip {...totalValuesToBeDisplayedOnList} />
          </PaginationColumn>
        )}
      </Grid>
    </ThemeProvider>
  );
};
