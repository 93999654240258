import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { IconButton, TextField } from '@mui/material';

import { trackMessage } from '../../../../pendoUtils';
import { modifyOrder } from '../../../../setup/api';
import { useFetch } from '../../../../setup/hooks/fetch.hook';
import { ClipBoardText } from '../../../../ui/components/ClipBoardText/ClipBoardText';

export const OrderNameField = (name: string, callback?: () => void) => {
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const [orderName, setOrderName] = useState<string>(name || '');
  const [isOrderNameFieldOpen, setIsOrderNameFieldOpen] = useState(false);
  const [errorField, setErrorField] = useState<string>();
  const { request } = useFetch();

  const updateOrderName = () => {
    const trimmedOrderName = orderName.trim();

    request(modifyOrder, id, { orderName: trimmedOrderName }).then(() => {
      trackMessage('MODIFY_ORDER_NAME_WEB', 'modify_order_name');

      setIsOrderNameFieldOpen(false);
      if (callback) {
        callback();
      }
    });
  };

  return (
    <>
      <div
        className="fw-bold"
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          display: 'block',
        }}
      >
        <DirectionsCarIcon fontSize="inherit" />
        <FormattedMessage id="ORDER.VIEW.ORDER_NAME" />
      </div>
      {isOrderNameFieldOpen ? (
        <div className="d-flex mt-2">
          <TextField
            id="standard-basic"
            error={Boolean(errorField)}
            fullWidth
            onKeyUp={e => {
              const isEnterKeyPressed = e.key === 'Enter';

              if (isEnterKeyPressed) {
                updateOrderName();
              }
            }}
            helperText={errorField}
            onChange={e => {
              if (e.target.value.length <= 3) {
                setErrorField(intl.formatMessage({ id: 'ORDER.NAME.ERROR.MIN' }));
              } else if (e.target.value.length >= 60) {
                setErrorField(intl.formatMessage({ id: 'ORDER.NAME.ERROR.MAX' }));
              } else {
                setErrorField(undefined);
              }
              setOrderName(e.target.value);
            }}
            sx={{
              '& label.Mui-focused': {
                display: 'none',
                height: '40px',
              },
              '& legend': {
                display: 'none',
              },
            }}
            inputProps={{ style: { height: '0.1em' } }}
            value={orderName}
          />
          <IconButton
            color="primary"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (!errorField) {
                updateOrderName();
              }
            }}
          >
            <SaveIcon />
          </IconButton>
        </div>
      ) : (
        <div
          style={{
            minWidth: '100px',
            minHeight: '20px',
            display: 'flex',
            alignItems: 'unset',
          }}
          onDoubleClick={() => {
            setIsOrderNameFieldOpen(true);
          }}
        >
          <ClipBoardText textToClipBoard={orderName} textToDisplay={orderName} />
          <EditIcon
            color="primary"
            style={{
              cursor: 'pointer',
              fontSize: '16px',
            }}
            onClick={() => {
              setIsOrderNameFieldOpen(true);
            }}
            className="ms-5"
          />
        </div>
      )}
    </>
  );
};
