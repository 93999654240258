import React, { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { checkIsActive } from '../../../../ui/helpers';

type Props = {
  to: string;
  title: string;
};

export const MenuItem: FC<Props> = ({ to, title }) => {
  const location = useLocation();

  useEffect(() => {
    // Call pendo's pageLoad method when the route changes
    window.pendo &&
      window.pendo.pageLoad({
        page: location.pathname,
      });
  }, [location]);
  return (
    <div className="menu-item me-lg-1">
      <Link className={`menu-link py-3 ${checkIsActive(location.pathname, to) && 'active'}`} to={to}>
        <span className="menu-title">
          <FormattedMessage id={title} />
        </span>
      </Link>
    </div>
  );
};
