import { FC, useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

import { trackMessage } from '../../../pendoUtils';
import { getFlawlessOrderStatistics } from '../../../setup/api/statistics';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { isLocalDataExpired, LocalStorageKey, useLocalStorage } from '../../../setup/hooks/useLocalStorage';
import { DashboardCard, DashboardCardHeader } from '../../../ui/components/MainGridContainer/DashboardGrid';
import { TitleTag } from '../../../ui/components/Title/TitleTag';

type DeviceStatisticsResult = {
  totalFlawlessOrders: number;
  totalOrdersWithFlaws: number;
  expires?: string;
};

export const FlawlessOrdersPieChart: FC = () => {
  const intl = useIntl();
  const { request, abortController, isLoading } = useFetch();
  const [shouldLoadChart, setShouldLoadChart] = useState<boolean>(false);
  const [statistics, setStatistics] = useLocalStorage<DeviceStatisticsResult>(
    LocalStorageKey.FLAWLESS_ORDERS_STATISTICS,
  );

  useEffect(() => {
    if (shouldLoadChart && isLocalDataExpired(statistics?.expires)) {
      request(getFlawlessOrderStatistics).then(res => {
        if (res) {
          setStatistics(res);
        }
        return () => {
          abortController.abort();
        };
      });
    }
  }, [shouldLoadChart]);

  const options = {
    labels: [intl.formatMessage({ id: 'STATS.NOT_EDITED' }), intl.formatMessage({ id: 'STATS.EDITED' })],
  };
  const series = [statistics?.totalFlawlessOrders, statistics?.totalOrdersWithFlaws];

  return (
    <DashboardCard>
      <DashboardCardHeader>
        <TitleTag children={<FormattedMessage id="STATS.EDITED.TITLE" />} />
        <Button
          variant="contained"
          onClick={() => {
            setShouldLoadChart(!shouldLoadChart);
            trackMessage('EDITED/NO_EDITED_ORDERS_ADMIN_DASHBOARD', 'Edited/no edited orders');
          }}
          children={<FormattedMessage id="STATS.LOAD" />}
        />
      </DashboardCardHeader>
      {isLoading && <LinearProgress style={{ margin: '2em' }} />}
      {statistics && (
        <Chart
          style={{ display: 'flex', justifyContent: 'center' }}
          options={options}
          series={series}
          type="pie"
          width="380"
        />
      )}
    </DashboardCard>
  );
};
