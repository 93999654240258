import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { OrderQAStatus, OrderStatus } from '../../../constants';
import { trackMessage } from '../../../pendoUtils';
import { RootState } from '../../../setup';
import { useQueryParams } from '../../../setup/hooks/useQueryParams';

import { getQueryOrEmptyString } from './helpers';

export type DropdownOption = {
  label: string;
  value: string;
};
export type TFilterProps = {
  name: string;
  options: DropdownOption[];
  query: string;
};

/**
 * A dropdown menu for selecting a filter value.
 *
 * @param {string} props.title - The label for the dropdown menu.
 * @param {string[]} props.values - The list of filter values to display in the dropdown menu.
 * @param {string} props.query - The name of the query parameter to set when a filter value is selected.
 * @returns {JSX.Element} A dropdown menu for selecting a filter value.
 */
export const FilterDropDown: React.FC<TFilterProps> = ({ name, options, query }: TFilterProps) => {
  const [queries, setQueries] = useQueryParams();
  const userRole = useSelector((state: RootState) => state.auth.user?.userRole);
  const [isOpen, setOpen] = useState<boolean>(false);
  const toggleDropdown = () => {
    setOpen(!isOpen);
  };

  return (
    <FormControl fullWidth>
      <InputLabel>{name}</InputLabel>
      <Select
        open={isOpen}
        onClose={toggleDropdown}
        onOpen={toggleDropdown}
        value={getQueryOrEmptyString(queries[query])}
        inputProps={{ name: 'name' }}
        label={name}
        onChange={(e: SelectChangeEvent<any>) => {
          setQueries(query, e.target.value);
          if (Object.values(OrderStatus).includes(e.target.value)) {
            trackMessage(
              `${userRole?.toUpperCase()}-ORDER_STATUS_FILTER`,
              `${userRole?.toUpperCase()} filter orders by status`,
            );
          }

          if (Object.values(OrderQAStatus).includes(e.target.value)) {
            trackMessage(
              `${userRole?.toUpperCase()}-ORDER_QA_STATUS_FILTER`,
              `${userRole?.toUpperCase()} filter orders by qa status`,
            );
          }

          if (Object.values(['All', 'Videos']).includes(e.target.value)) {
            trackMessage(
              `${userRole?.toUpperCase()}-HAS_VIDEO_FILTER`,
              `${userRole?.toUpperCase()} filter if order has video`,
            );
          }
        }}
      >
        {/* Render a MenuItem for each value in the "values" prop */}
        {options.map(({ label, value }: DropdownOption, index: number) => (
          <MenuItem value={value} key={index}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
