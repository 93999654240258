/* eslint-disable @typescript-eslint/ban-types */

import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Moment from 'react-moment';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

import {
  getOrderDeliveryMessageAvailableCount,
  getOrderDeliveryMessages,
  rebootAWSRobot,
  removeOrderDeliveryMessage,
  setOrderMessageVisibility,
} from '../../../../../setup/api/orderDeliveryMessageAPI';
import { useFetch } from '../../../../../setup/hooks/fetch.hook';
import { alertPayload, useFetchWithAlert } from '../../../../../setup/hooks/useFetchWithAlert';
import { DataGridV2 } from '../../../../../ui/components/Datagrid/DataGridV2';
import { FilterComponent } from '../../../../../ui/components/Filters/FilterComponent';
import { ModalCarswip } from '../../../../../ui/components/Modal';
import { RemoveOrderFromQueueModal } from '../../../../../ui/components/Modals/RemoveOrderFromQueueModal';
import { ModalSubtitle } from '../../../../../ui/components/Title/ModalSubtitle';

type OrderDeliveryMessage = {
  id: string;
  lastAccessed: string;
  order: IOrder;
  integration: RemoteIntegration;
  createdAt: string;
};
type OrderDeliveryResult = {
  deliveryMessages: OrderDeliveryMessage[];
  count: number;
};

const RebootAWSModal: FC = () => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const { request, isLoading } = useFetchWithAlert(alertPayload(intl.formatMessage({ id: 'ORDER.DELIVERY.REBOOT' })));

  const handleReboot = () => {
    request(rebootAWSRobot).then(() => {
      setIsOpen(false);
    });
  };

  useEffect(() => {
    // Call pendo's pageLoad method when the route changes
    window.pendo &&
      window.pendo.pageLoad({
        page: location.pathname,
      });
  }, [location]);

  return (
    <>
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
        variant="contained"
        color="warning"
        children={<FormattedMessage id="ORDER.DELIVERY.REBOOT" />}
      />
      <ModalCarswip
        title={intl.formatMessage({ id: 'ORDER.DELIVERY.REBOOT.CONFIRM' })}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        fullWidth={false}
      >
        <ModalSubtitle children={<FormattedMessage id="ORDER.DELIVERY.REBOOT.WARNING" />} />
        <Box display="flex" justifyContent="flex-end">
          <Button
            children={<FormattedMessage id="ORDER.DELIVERY.REBOOT.CONFIRM" />}
            variant="contained"
            color="error"
            disabled={isLoading}
            onClick={handleReboot}
          />
        </Box>
      </ModalCarswip>
    </>
  );
};

/**
 * Renders the rows for {@link DataGrid}
 * @param {Array<[string, string]>} columnHeaders must include an array with a tuple of strings, each of the keys in the object will be used as the column headings
 * @param  item the item should be the customer payload which will be use to load a row with information
 * @returns table row with a key for each row
 */
const renderRowsForOrderDeliveryMessageGrid = (
  columnHeaders: Array<[keyof OrderDeliveryMessage, string]>,
  item: OrderDeliveryMessage,
) =>
  columnHeaders.map(([objectKey]) => {
    const split = objectKey.split('_');

    if (split.length > 1) {
      if (item[split[0] as keyof OrderDeliveryMessage] !== null) {
        return <td key={objectKey}>{item[split[0] as keyof object][split[1]]}</td>;
      }
    }
    if (objectKey === 'lastAccessed' && item[objectKey]) {
      return (
        <td key={objectKey}>
          <Moment format="YYYY-MM-DD HH:mm:ss" date={item[objectKey]} />
        </td>
      );
    }
    if (objectKey === 'createdAt') {
      return (
        <td key={objectKey}>
          <Moment format="YYYY-MM-DD HH:mm:ss" date={item[objectKey]} />
        </td>
      );
    }

    return <td key={objectKey}>{item[objectKey]}</td>;
  });

export const OrderDeliveryMessageList: FC = () => {
  const intl = useIntl();
  const { request, isLoading, abortController } = useFetch();
  const [openModalIfIdIsSelected, setOpenModalIfIdIsSelected] = useState<string>();
  const removeFetch = useFetchWithAlert(alertPayload(intl.formatMessage({ id: 'ORDER.DELIVERY.ALERT.REMOVE' })));
  const makeVisible = useFetchWithAlert(alertPayload(intl.formatMessage({ id: 'ORDER.DELIVERY.ALERT.VISIBLE' })));
  const queriesFromRouter = useLocation<string>().search;
  const [orderDeliveryResult, setOrderDeliveryResult] = useState<OrderDeliveryResult>({
    deliveryMessages: [],
    count: 0,
  });
  const [currentAvailableCount, setCurrentAvailableCount] = useState(0);
  const [selectedOrderDeliveryMessage, setSelectedOrderDeliveryMessage] = useState<string[]>([]);

  const orderDeliveryMessagesHeaders: Array<[string, string]> = [
    ['order_orderName', intl.formatMessage({ id: 'ORDER' })],
    ['createdAt', intl.formatMessage({ id: 'ORDER.DELIVERY.CREATED' })],
    ['lastAccessed', intl.formatMessage({ id: 'ORDER.DELIVERY.ATTEMPT' })],
    ['integration_provider', intl.formatMessage({ id: 'ORDER.DELIVERY.DMS' })],
  ];

  const fetchOrderDeliveryMessages = () => {
    request(getOrderDeliveryMessages, queriesFromRouter, abortController.signal).then(
      (res: IListResponseAndCount<OrderDeliveryMessage>) => {
        if (res) {
          const [deliveryMessages, count] = res;

          setOrderDeliveryResult({
            deliveryMessages,
            count,
          });
        }
      },
    );
  };

  const fetchOrderDeliveryCount = () => {
    request(getOrderDeliveryMessageAvailableCount, abortController.signal).then(res => {
      if (res) {
        setCurrentAvailableCount(res.availableMessageCount);
      }
    });
  };

  const handleRemoveOrderFromQueue = () => {
    removeFetch.request(removeOrderDeliveryMessage, openModalIfIdIsSelected).then(() => {
      setOpenModalIfIdIsSelected(undefined);
      fetchOrderDeliveryMessages();
    });
  };

  const removeOrderDeliveryMessageProp = {
    // Must be async function so it can be resolved inside another component
    requestData: async (orderDeliveryMessageId: string) => {
      setOpenModalIfIdIsSelected(orderDeliveryMessageId);
    },
    actionName: intl.formatMessage({ id: 'ORDER.DELIVERY.REMOVE' }),
  };
  const makeOrderDeliveryMessageVisibleProps = {
    // Must be async function so it can be resolved inside another component
    requestData: async (orderDeliveryMessageId: string) => {
      makeVisible.request(setOrderMessageVisibility, orderDeliveryMessageId).then(() => {
        fetchOrderDeliveryMessages();
      });
    },
    actionName: intl.formatMessage({ id: 'ORDER.DELIVERY.VISIBLE' }),
  };

  useEffect(() => {
    fetchOrderDeliveryCount();
  }, []);

  useEffect(() => {
    fetchOrderDeliveryMessages();
    return () => {
      abortController.abort();
    };
  }, [queriesFromRouter]);

  return (
    <Grid container padding={4} className="card">
      <Grid xs={12} marginBottom={2} display="flex" justifyContent="flex-end">
        <RebootAWSModal />
      </Grid>
      <Grid xs={12} display="flex" justifyContent="flex-end">
        <Chip
          label={intl.formatMessage({ id: 'ORDER.DELIVERY.COUNT' }, { count: currentAvailableCount })}
          style={{
            fontWeight: '500',
            fontSize: '1.075rem',
            marginRight: '.5em',
          }}
          color="error"
        />
      </Grid>
      <Grid xs={12} marginTop={3}>
        <FilterComponent
          showSearchFilter
          showPagination
          entity="orderDeliveryMessages"
          count={orderDeliveryResult.count}
        />
      </Grid>
      <Grid xs={12} marginTop={3}>
        <DataGridV2
          data={orderDeliveryResult.deliveryMessages}
          loading={isLoading}
          setSelected={setSelectedOrderDeliveryMessage}
          selected={selectedOrderDeliveryMessage}
          headings={orderDeliveryMessagesHeaders}
          renderRow={renderRowsForOrderDeliveryMessageGrid}
          customMenu={[makeOrderDeliveryMessageVisibleProps, removeOrderDeliveryMessageProp]}
        />
      </Grid>
      <RemoveOrderFromQueueModal
        isOpen={openModalIfIdIsSelected}
        setIsOpen={setOpenModalIfIdIsSelected}
        removeOrderDeliveryMessage={handleRemoveOrderFromQueue}
      />
    </Grid>
  );
};
