import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Button, Grid } from '@mui/material';

import { addStyleGuideByUserId, getStyleGuides, getUser, removeStyleGuideFromUser } from '../../../../setup/api';
import { useFetch } from '../../../../setup/hooks/fetch.hook';
import { alertPayload, useFetchWithAlert } from '../../../../setup/hooks/useFetchWithAlert';
import { DataGridV2 } from '../../../../ui/components/Datagrid/DataGridV2';
import { VerticalDivider } from '../../../../ui/components/Divider/VerticalDivider';
import { FilterComponent } from '../../../../ui/components/Filters/FilterComponent';
import { renderStyleGuidesOnCreateUser } from '../../../pages/account/CreateDealership';
import { UserModel } from '../../../pages/auth/interfaces';
import { CreateStyleGuideButton } from '../CreateStyleGuideButton';

const removeStyleGuideFromUserButton = (id: string, request: (id: string) => Promise<void>, disabled: boolean) => (
  <Button
    variant="contained"
    color="error"
    disabled={!disabled}
    onClick={() => {
      request(id);
    }}
  >
    <RemoveCircleOutlineIcon />
    <FormattedMessage id="ACTION.REMOVE" />
  </Button>
);

const addStyleGuideFromUserButton = (id: string, request: (id: string) => Promise<void>, disabled: boolean) => (
  <Button
    variant="contained"
    color="primary"
    disabled={disabled}
    onClick={() => {
      request(id);
    }}
  >
    <AddIcon />
    <FormattedMessage id={disabled ? 'CHILD.ACTION.DISABLED' : 'CHILD.ACTION.ADD'} />
  </Button>
);

/**
 * FIXME: the component is not completed yet
 * @returns
 */
export const UserStyleGuideSettings: React.FC = () => {
  const intl = useIntl();
  const { id } = useParams<{ id: string }>();
  const queries = useLocation<string>().search;
  const [userStyleGuides, setUserStyleGuides] = useState<IStyleGuide[]>([]);
  const [styleGuideList, setStyleGuideList] = useState<IStyleGuide[]>([]);
  const [selectedStyleGuides, setSelectedStyleGuides] = useState<string[]>([]);
  const [totalStyleGuide, setTotalStyleGuides] = useState<number>();
  const { request, abortController } = useFetch();

  const addFetch = useFetchWithAlert(alertPayload(intl.formatMessage({ id: 'STYLE_GUIDE.USER.ALERT.ADDED' })));
  const removeFetch = useFetchWithAlert(alertPayload(intl.formatMessage({ id: 'STYLE_GUIDE.USER.ALERT.REMOVED' })));

  const dataGridHeadings: [string, string][] = [
    ['name', intl.formatMessage({ id: 'REPUSH.SETTINGS.STYLEGUIDE.TABLE.NAME' })],
    ['logo_thumbnailUrl', intl.formatMessage({ id: 'STYLE_GUIDE.CONFIGURATION.LOGO' })],
    ['background_thumbnailUrl', intl.formatMessage({ id: 'REPUSH.SETTINGS.STYLEGUIDE.TABLE.BACKGROUND' })],
    ['floor_thumbnailUrl', intl.formatMessage({ id: 'REPUSH.SETTINGS.STYLEGUIDE.TABLE.FLOOR' })],
  ];

  useEffect(() => {
    // Call pendo's pageLoad method when the route changes
    window.pendo &&
      window.pendo.pageLoad({
        page: location.pathname,
      });
  }, [location]);

  const addStyleGuides = (styleGuideId: string) =>
    addFetch.request(addStyleGuideByUserId, id, styleGuideId).then(() => {
      request(getUser, id).then(res => {
        setUserStyleGuides(res.styleGuides);
      });
    });

  const removeStyleGuideButtonProp = {
    requestData: async (styleGuideId: string) => {
      removeFetch.request(removeStyleGuideFromUser, id, styleGuideId).then(() => {
        request(getUser, id).then(res => {
          setSelectedStyleGuides(selectedStyleGuides.filter(id => id !== styleGuideId));

          setUserStyleGuides(res.styleGuides);
        });
      });
    },
    button: removeStyleGuideFromUserButton,
    actionName: 'Remove',
  };

  const addStyleGuideButtonProp = {
    requestData: async (styleGuideId: string) => {
      addStyleGuides(styleGuideId).then(() => {
        setSelectedStyleGuides([...selectedStyleGuides, styleGuideId]);
      });
    },
    button: addStyleGuideFromUserButton,
    actionName: 'Add',
  };

  useEffect(() => {
    request(getStyleGuides, queries, abortController.signal).then(res => {
      if (res) {
        setStyleGuideList(res[0]);
        setTotalStyleGuides(res[1]);
      }
    });
    return () => {
      abortController.abort();
    };
  }, [queries]);

  useEffect(() => {
    request(getUser, id).then(({ styleGuides }: UserModel) => {
      if (styleGuides) {
        const selected: string[] = [];

        styleGuides?.forEach(({ id }: IStyleGuide) => {
          if (id) {
            selected.push(id);
          }
        });
        setUserStyleGuides(styleGuides);

        if (selected) {
          setSelectedStyleGuides(selected);
        }
      }
    });
  }, []);

  return (
    <div className="content d-flex flex-column flex-column-fluid">
      <Grid container paddingTop={6.5} className="card">
        <Grid container justifyContent="space-around" alignItems="center">
          <Grid item xs={5}>
            <FilterComponent showSearchFilter showPagination entity="styleGuides" count={totalStyleGuide} />
          </Grid>
          <Grid item xs={5} justifyContent="end" display="flex">
            <CreateStyleGuideButton />
          </Grid>
        </Grid>
        <Grid container justifyContent="space-around" spacing={2} marginBottom="10em">
          <Grid item xs={5} sx={{ marginTop: '5em' }}>
            <label className="d-flex align-items-center fs-5 fw-bold mb-2">
              <span>
                <FormattedMessage id="STYLE_GUIDE.USER.ALL" />
              </span>
            </label>

            <DataGridV2
              data={styleGuideList}
              setSelected={setSelectedStyleGuides}
              selected={selectedStyleGuides}
              buttonCustom={addStyleGuideButtonProp}
              headings={dataGridHeadings}
              renderRow={renderStyleGuidesOnCreateUser}
            />
          </Grid>
          <VerticalDivider sx={{ marginLeft: '-10em', marginRight: '-10em', marginBottom: '-10em' }} />
          <Grid item xs={5} sx={{ marginTop: '5em' }}>
            <label className="d-flex align-items-center fs-5 fw-bold mt-1">
              <span>
                <FormattedMessage id="STYLE_GUIDE.USER.TITLE" />
              </span>
            </label>
            <DataGridV2
              data={userStyleGuides}
              buttonCustom={removeStyleGuideButtonProp}
              setSelected={setSelectedStyleGuides}
              selected={selectedStyleGuides}
              headings={[dataGridHeadings[0]]}
              renderRow={renderStyleGuidesOnCreateUser}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
