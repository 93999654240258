import { Dispatch, FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';

import { getStyleGuide, getStyleGuides } from '../../../setup/api';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import { Datagrid } from '../../../ui/components/Datagrid/Datagrid';
import { FilterComponent } from '../../../ui/components/Filters/FilterComponent';
import { renderStyleGuidesOnCreateUser } from '../../pages/account/CreateDealership';

interface IStyleGuideGridProps {
  getSelectedStyleGuideInfo: Dispatch<IStyleGuide>;
}
export const StyleGuideTable: FC<IStyleGuideGridProps> = ({ getSelectedStyleGuideInfo }) => {
  const intl = useIntl();
  const [styleGuides, setStyleGuides] = useState<IStyleGuide[]>([]);
  const [selectedStyleGuides, setSelectedStyleGuides] = useState<any>({});
  const [styleGuideCount, setStyleGuideCount] = useState<number>();
  const location = useLocation();
  const queriesFromRouter = location.search;
  const [openGrid, setOpenGrid] = useState(false);
  const { request } = useFetch();
  const getStyleGuideById = async (id: string) => {
    request(getStyleGuide, id).then((styleGuideInformation: IStyleGuide) => {
      getSelectedStyleGuideInfo(styleGuideInformation);
    });
  };

  useEffect(() => {
    // Call pendo's pageLoad method when the route changes
    window.pendo &&
      window.pendo.pageLoad({
        page: location.pathname,
      });
  }, [location]);

  useEffect(() => {
    request(getStyleGuides, queriesFromRouter).then((styleGuides: IListResponseAndCount<IStyleGuide>) => {
      if (styleGuides) {
        setStyleGuideCount(styleGuides[1]);
        setStyleGuides(styleGuides[0]);
      }
    });
  }, [queriesFromRouter]);

  useEffect(() => {
    if (Object.keys(selectedStyleGuides).length > 0) {
      setOpenGrid(false);
      getStyleGuideById(Object.keys(selectedStyleGuides)[0]);
    }
  }, [selectedStyleGuides]);

  return (
    <div className="container mt-5">
      {!openGrid && (
        <div className="justify-content-center">
          <Button
            style={{ margin: '10px', height: '35px', width: '200px' }}
            variant="contained"
            color="primary"
            onClick={() => {
              setSelectedStyleGuides({});
              setOpenGrid(!openGrid);
            }}
          >
            <FormattedMessage id="REPUSH.SETTINGS.STYLEGUIDE.CHANGE" />
          </Button>
        </div>
      )}
      {openGrid && (
        <div>
          <Grid xs={12} marginTop={3}>
            <FilterComponent showSearchFilter showPagination entity="styleGuides" count={styleGuideCount} />
          </Grid>

          <div className="col-12" style={{ minHeight: '300px' }}>
            <Datagrid
              data={styleGuides}
              selectable
              setSelected={setSelectedStyleGuides}
              selected={selectedStyleGuides}
              headings={[
                ['name', intl.formatMessage({ id: 'REPUSH.SETTINGS.STYLEGUIDE.TABLE.NAME' })],
                ['background_thumbnailUrl', intl.formatMessage({ id: 'REPUSH.SETTINGS.STYLEGUIDE.TABLE.BACKGROUND' })],
                ['floor_thumbnailUrl', intl.formatMessage({ id: 'REPUSH.SETTINGS.STYLEGUIDE.TABLE.FLOOR' })],
              ]}
              renderRow={renderStyleGuidesOnCreateUser}
            />
          </div>
        </div>
      )}
    </div>
  );
};
