export const loadPendoScript = (apiKey = 'YOUR_PENDO_API_KEY') => {
  const script = document.createElement('script');

  script.async = true;
  script.innerHTML = `
        (function(apiKey){
            (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
            v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
                o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
                y=e.createElement(n);y.async=!0;y.src='https://cdn.eu.pendo.io/agent/static/'+apiKey+'/pendo.js';
                z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);
            })(window,document,'script','pendo');
        })('7c3b8b23-2e29-48c9-464c-126674c540c2');
    `;

  document.body.appendChild(script);
};

export const initializePendo = (userData: any, env: string) => {
  if (window.pendo) {
    try {
      window.pendo.initialize({
        apiKey: '7c3b8b23-2e29-48c9-464c-126674c540c2',
        visitor: {
          id: userData.displayName,
        },
        account: {
          id: env,
        },
      });
    } catch (error) {
      console.error('Error initializing pendo');
    }
  }
};

export const trackMessage = (trackName: string, formName: string) => {
  try {
    window.pendo.track(trackName, {
      formName,
      timestamp: new Date().toISOString(),
    });
  } catch (error) {
    console.error('ERROR TRACKING MESSAGE');
  }
};
