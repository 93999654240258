import { FC, useEffect, useRef, useState } from 'react';
import config from 'react-global-configuration';
import { connect, ConnectedProps, shallowEqual, useDispatch, useSelector } from 'react-redux';

import { LayoutSplashScreen } from '../../../_carswip/layout/core';
import { initializePendo, loadPendoScript } from '../../../pendoUtils';
import { RootState } from '../../../setup';
import { getMe } from '../../../setup/api/auth';
import { useFetch } from '../../../setup/hooks/fetch.hook';
import * as auth from '../../../setup/redux/auth/AuthRedux';

const mapState = (state: RootState) => ({ auth: state.auth });
const connector = connect(mapState, auth.actions);

type PropsFromRedux = ConnectedProps<typeof connector>;

const AuthInit: FC<PropsFromRedux> = ({ fulfillUser, logout, children }) => {
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  const { request } = useFetch();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const accessToken = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual);

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = () => {
      loadPendoScript();
      if (!didRequest.current) {
        request(getMe).then(data => {
          if (data) {
            dispatch(fulfillUser(data.result));
            initializePendo(data.result, config.get('Auth').environment);
          } else {
            dispatch(logout());
          }
          setShowSplashScreen(false);
        });
      }

      return () => (didRequest.current = true);
    };

    if (accessToken) {
      requestUser();
    } else {
      dispatch(logout());
      setShowSplashScreen(false);
    }
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export default connector(AuthInit);
