import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import { trackMessage } from '../../../pendoUtils';
import { addNewFilters } from '../../../setup/redux/actions';
import { IFilterConfigurations } from '../../../setup/redux/reducers/selectedFilters';

type TProps = {
  type: string;
  defaultPage: number;
  maxPage?: number;
  disableLeftArrow?: boolean;
};

/**
 *  Display filter pagination dropdown
 *
 * @param { string  } props.type redux type
 * @param { number } props.defaultPage default page to be use on the filter
 * @param { number } props.maxPage
 *
 * @return send the selected page to redux
 */
const PaginationFilter: React.FC<TProps> = ({ type, defaultPage, maxPage, disableLeftArrow = false }) => {
  const [page, setPage] = useState<number>(defaultPage);
  const [value, setValue] = useState<IFilterConfigurations | any>({
    page: defaultPage,
  });

  trackMessage('INACTIVE_USERS_PAGINATION', 'Inactive Users pagination click');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addNewFilters({ ...value }, type));
  }, [value]);

  useEffect(() => {
    setPage(defaultPage);
  }, [defaultPage]);

  const getLastPage = () => {
    if (maxPage) {
      setPage(Math.ceil(maxPage));
      setValue({ page: Math.ceil(maxPage) });
    }
  };
  const getFirstPage = () => {
    setPage(1);
    setValue({ page: 1 });
  };
  const previousPage = () => {
    if (page > 0) {
      setPage(page - 1);
      setValue({ page: page - 1 });
    }
  };
  const nextPage = () => {
    if (maxPage) {
      const maxPageInteger = Math.ceil(maxPage);

      if (maxPageInteger && maxPageInteger > page) {
        setPage(page + 1);
        setValue({ page: page + 1 });
      }
    } else {
      setPage(page + 1);
      setValue({ page: page + 1 });
    }
  };

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination justify-content-center">
        <li className={`d-flex ${page === 1 ? 'page-item disabled' : 'page-item  '}`}>
          {!disableLeftArrow && (
            <>
              <a className="page-link">
                <KeyboardDoubleArrowLeftIcon onClick={getFirstPage} />
              </a>
              <a className="page-link">
                <KeyboardArrowLeftIcon onClick={previousPage} />
              </a>
            </>
          )}
        </li>
        <li className="page-item">
          <a className="page-link">{page}</a>
        </li>

        <li className="page-item d-flex">
          <a className="page-link">
            <KeyboardArrowRightIcon onClick={nextPage} />
          </a>
          <a className="page-link">
            <KeyboardDoubleArrowRightIcon onClick={getLastPage} />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export { PaginationFilter };
